<template>
  <b-container fluid="">
    <b-row>
      <b-col sm="12" lg="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Tambah Pejabat Baru</h4>
          </template>
          <template v-slot:body>
            <form >
              <div class="form-row">
                <b-col md="12" class="mb-3">
                  <label for="code">Nama Pegawai<strong>*</strong></label>
                  <v-select :options="dataPegawai" :reduce="code => code.code" label="label" v-model="form.pegawai_id" class="style-chooser">
                  </v-select>
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="name">Autorisasi<strong>*</strong></label>
                  <v-select :options="dataAutorisasi" v-model="form.autorisasi" class="style-chooser" @input="otorisasiSelected">
                  </v-select>
                </b-col>
                <b-col md="12" class="mb-3" v-if="form.autorisasi == 'Pejabat Pelaksana Teknis Kegiatan' || form.autorisasi == 'Bendahara'">
                  <label for="nip">Anggaran</label>
                  <v-select :options="optAnggaran" :reduce="code => code.id" label="nama_rekening" v-model="form.anggaran_id" class="style-chooser"></v-select>
                </b-col>
                <b-col md="12" class="form-group col-sm-6">
                  <label class="d-block">Status Aktif</label>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" v-model="form.is_active" id="ya" value="1" class="custom-control-input">
                    <label class="custom-control-label" for="ya"> Ya </label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" v-model="form.is_active" value="0" id="tidak" class="custom-control-input">
                    <label class="custom-control-label" for="tidak"> Tidak </label>
                  </div>
                </b-col>
              </div>
              <div class="float-right">
                <button type="submit" class="btn bg-danger mr-1" @click.prevent="formCancel()">Batal</button>
                <button type="submit" class="btn btn-primary" @click.prevent="formSave()">Simpan</button>
              </div>
            </form>
          </template>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { getDataService, postDataService } from '../../../store/modules/crudservices'

export default {
  created(){
    document.title = "Tambah Bidang"
  },
  name:'PejabatAdd',
  data() {
    return {
      optAnggaran: [],
      dataPegawai:[],
      dataAutorisasi:["Petugas Tanda Tangan", "Pejabat Pelaksana Teknis Kegiatan", "Sekretaris", "Bendahara"],
      form:{
        pegawai_id: null,
        autorisasi: null,
        is_active: null,
        anggaran_id: null
      },
    }
  },
  methods: {
    getPegawai() {
      const url = '/pegawai-search?filter=all';
      getDataService(url).then(response => {
        this.dataPegawai = response.data
      })
    },
    getAnggaran() {
      const url = '/anggaran-search';
      getDataService(url).then(response => {
        this.optAnggaran = response.data
      })
    },
    formSave(){
      const form = this.form
      const _data = {
        "pegawai_id": form.pegawai_id,
        "autorisasi": form.autorisasi,
        "is_active": form.is_active,
        "anggaran_id": form.anggaran_id
      }
      
      postDataService('/pejabat', _data).then(response => {
        // console.log(response)
        if(typeof response.data.success !== 'undefined') this.$router.push('/master/pejabat')
      })
    },
    formCancel(){
      this.$router.push('/master/pejabat') 
    },
    otorisasiSelected(value) {
      if (value != 'Pejabat Pelaksana Teknis Kegiatan') this.form.anggaran_id = null
    }
  },
  mounted() {
    this.getPegawai()
    this.getAnggaran()
  }
}
</script>